import React from 'react'
import ReactDOM from 'react-dom'

const ChatWidget = React.lazy(() => import('./components/ChatWidget'))

// React.StrictMode :
// Identifying components with unsafe lifecycles
// Warning about legacy string ref API usage
// Warning about deprecated findDOMNode usage
// Detecting unexpected side effects
// Detecting legacy context API

ReactDOM.render(
  <React.Suspense fallback={<div></div>}>
    <React.StrictMode>
      <ChatWidget />
    </React.StrictMode>
  </React.Suspense>,
  document.getElementById('chat-snap-in-widget')
)
